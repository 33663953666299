<template>
  <el-select
    class="select"
    v-show="componentList.some((t) => t.component === this.component)"
    v-model="myValue"
    :multiple="multiple"
    :size="size"
    clearable
    collapse-tags
    @change="handleChange"
  >
    <el-option
      v-for="item in list"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { bannerCategoryAll } from '@/modules/app-design/api/components'
export default {
  name: 'StChannelsCategoryFilter',
  props: {
    value: {
      type: [Array, String, Number],
      default: function () {
        return ''
      },
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    component: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      list: [],
      myValue: '',
      componentList: [
        { component: 'st-banner', initCategories: bannerCategoryAll },
      ],
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue === -1 ? '' : newValue
    },
    component: {
      handler(val) {
        const index = this.componentList.findIndex((t) => t.component === val)
        if (index !== -1) {
          this.loading = true
          this.list = []
          this.componentList[index]
            .initCategories()
            .then((res) => {
              this.myValue = ''
              this.list = res.data
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          this.list = []
        }
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    handleChange(e) {
      let value = this.multiple ? e : e === '' ? -1 : e
      console.log(value)
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.select {
  ::v-deep.el-input {
    .el-input__inner {
      height: 40px;
    }
  }
}
</style>
