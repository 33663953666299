<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="页面背景图：">
        <image-wall
          v-model="form.bg_image"
          fit="contain"
          :width="194"
          :height="135"
          ratio="136:136"
        >
          <!-- <p slot="info" class="img-tips" style="margin-top: 8px">
                  <span class="el-icon-info"></span>建议上传尺寸比例为136px*136px,不上传图片时，默认使用系统自带图片
                </p> -->
        </image-wall>
        <el-input v-show="false" v-model="form.bg_image"></el-input>
      </el-form-item>
      <el-form-item label="轮播图：">
        <el-radio-group v-model="form.banner_status">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <ad-table v-model="form.banners"></ad-table>
      </el-form-item>

      <div class="modular">
        <p class="modular-title">分享配置</p>
        <div class="modular-content">
          <el-form-item label="分享标题：" prop="share_title">
            <el-input
              style="width: 300px"
              type="text"
              v-model="form.share_title"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="分享图片：" prop="share_image">
            <SingleMediaWall
              v-model="form.share_image"
              :width="140"
              :height="140"
              ratio="5:4"
              :show-cut="true"
            >
              <p slot="info" class="img-tips">
                建议上传尺寸比例为1500*1200，不上传图片时，默认使用系统自带图片
              </p>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import ImageWall from '@/modules/common/components/SingleMediaWall.vue'
import AdTable from '@/modules/employ/components/employ-config/AdTable.vue'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall.vue'

// 接口
import {
  getPageConfig,
  savePageConfig,
} from '@/modules/employ/api/employ-config'
export default {
  components: { FixedActionBar, ImageWall, AdTable, SingleMediaWall },
  data() {
    return {
      form: {
        banner_status: 0,
        banners: [],
        bg_image: '',
      },
      loading: false,
      // 校验规则
      rules: {},
    }
  },
  methods: {
    // 保存配置
    saveConfig() {
      let postData = {
        config: {
          ...this.form,
        },
      }
      // 保存商城数据
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true

          savePageConfig(postData)
            .then((res) => {
              this.$message.success(res.msg)

              this.getDatail()

              this.loading = false
            })
            .catch((err) => {
              this.loading = false
            })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    // 获取详情
    getDatail() {
      this.loading = true
      getPageConfig()
        .then((res) => {
          this.form = res.data
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  created() {
    this.getDatail()
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
.modular-content {
  margin-left: 11px;
}
</style>
