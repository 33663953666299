<template>
  <div>
    <div class="flex-align-center">
      <el-button type="primary" :disabled="disabled" @click="addAd"
        >添加广告</el-button
      >
      <p v-if="tips" class="tips">
        {{ tips }}
      </p>
    </div>
    <el-table
      :id="`sortTable${id}`"
      row-key="id"
      :data="modelList"
      class="thead-light"
      stripe
      :style="{ marginTop: '10px' }"
    >
      <el-table-column
        v-for="(item, index) in sortColumn"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.min_width"
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <!--排序 -->
          <div v-if="item.prop === 'sort'">
            <i class="el-icon-sort"></i>
          </div>
          <!-- 图标 -->
          <list-image
            v-else-if="item.prop === 'icon'"
            :src="scope.row[item.prop]"
            borderRadius="50%"
            fit="cover"
          ></list-image>
          <div class="status-text" v-else-if="item.prop === 'status_text'">
            <div
              class="status-dot"
              :style="{
                backgroundColor: scope.row.status_text_color,
              }"
            ></div>

            <div>{{ scope.row[item.prop] | placeholder }}</div>
          </div>
          <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <!-- <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleEditAd(scope.row)"
            >编辑
          </el-button> -->
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleDelAd(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <dataSelector
      v-model="dataSelectDialog.show"
      :selected="modelList"
      :dataType="dataSelectDialog.dataType"
      :form="dataSelectDialog.form"
      selectFullData
      @change="dataChange"
    />
  </div>
</template>

<script>
import ListImage from '@/base/components/List/ListImage'
import Sortable from 'sortablejs/modular/sortable.core.esm.js'
import dataSelector from '@/modules/app-design/components/Normal/dataSelector.vue'
export default {
  components: { ListImage, dataSelector },
  model: {
    prop: 'sortList',
    event: 'changeSortList',
  },
  props: {
    sortList: {
      type: Array,
      default: () => [],
    },
    tips: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 1,
    },
    defaultField: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortColumnList: [
        { label: '拖拽排序', prop: 'sort', min_width: 100 },
        { label: '广告名称', prop: 'name', min_width: 100 },
        { label: '广告分类', prop: 'category_name', min_width: 100 },
        { label: '广告类型', prop: 'banner_type_text', min_width: 100 },
        { label: '推广时段', prop: 'time_text', min_width: 100 },
        { label: '状态', prop: 'status_text', min_width: 100 },
      ],
      dataSelectDialog: {
        show: false,
        selected: [],
        dataType: '',
        form: {
          banner_type_all: 0,
          banner_types: [0, 2, 3],
        },
      },
    }
  },
  computed: {
    modelList: {
      get() {
        return this.$props.sortList
      },
      set(val) {
        this.$emit('changeSortList', val)
      },
    },
    sortColumn() {
      return this.sortColumnList.filter(
        (t) =>
          this.$props.defaultField.findIndex((f) => f.label == t.prop) === -1
      )
    },
  },
  methods: {
    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          `#sortTable${this.$props.id}  .el-table__body>tbody`
        )
        console.log(sortTable)
        this.$sortTable = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: 'sortable-ghost',
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex)
            if (event.oldIndex === event.newIndex) {
              return
            }
            // 拿到拖拽的item
            const oldItem = this.modelList[event.oldIndex]
            // 删除原本位置
            this.modelList.splice(event.oldIndex, 1)
            // 添加到新的位置
            this.modelList.splice(event.newIndex, 0, oldItem)
            let idArray = this.modelList.map((item) => item.id)
          },
        })
        //阻止火狐拖拽新建新页面
        document.body.addEventListener('drop', this.preventDrop, false)
      })
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable) this.$sortTable.destroy()
      document.body.removeEventListener('drop', this.preventDrop, false)
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault()
      event.stopPropagation()
    },
    // 删除此广告
    handleDelAd(row) {
      this.$confirm('是否删除此广告?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let index = this.modelList.findIndex((item) => item.id === row.id)
          this.modelList.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {})
    },
    addAd() {
      if (this.modelList.length >= 8) {
        this.$message({
          type: 'warning',
          message: '最多添加8个广告!',
        })
        return
      }
      this.dataSelectDialog.show = true
      this.dataSelectDialog.dataType = 'st-banner'
      this.dataSelectDialog.selected = this.modelList
    },
    // 添加广告成功
    dataChange(list) {
      console.log(list)
      let forData = []
      for (let i = 0; i < list.length; i++) {
        if (!forData.some((e) => e.id == list[i].id)) {
          forData.push(list[i])
        }
      }
      console.log(forData)
      this.modelList = forData
    },
  },
  mounted() {
    this.initSortable()
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable()
  },
}
</script>

<style lang="scss" scoped>
.tips {
  margin-left: 20px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
.status-text {
  display: flex;
  align-items: center;
  .status-dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
