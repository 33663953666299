<template>
  <el-select v-model="myValue" :multiple="multiple" :size="size"
             clearable collapse-tags @change="handleChange">
    <template v-for="(item) in list">
      <el-option :key="item.id" :label="item.name" :value="item.id"></el-option>
    </template>
  </el-select>
</template>

<script>
  import {allChannelsCategories} from "@/modules/app-design/api/components";

  export default {
    name: "StChannelsCategoryFilter",
    props: {
      value: {
        type: [Array, String, Number],
        default: function () {
          return ""
        }
      },
      multiple: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: "medium"
      }
    },
    data() {
      return {
        loading: true,
        list: [],
        myValue: ""
      }
    },
    watch: {
      value(newValue) {
        this.myValue = newValue === -1 ? '' : newValue
      }
    },
    created() {
      this.myValue = this.value === -1 ? '' : this.value
      this.initCategories()
    },
    methods: {
      initCategories() {
        allChannelsCategories().then(res => {
          this.list = res.data;
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      handleChange(e) {
        let value = this.multiple ? e : (e === '' ? -1 : e)
        this.$emit('input', value)
        this.$emit('change', value)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
