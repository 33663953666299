import api from '@/base/utils/request';


export const getPageConfig = data => {
  return api({
    url: '/admin/employ/settings/getPageConfig',
    method: 'post',
    data
  });
};
export const savePageConfig = data => {
  return api({
    url: '/admin/employ/settings/savePageConfig',
    method: 'post',
    data
  });
};
export const getProfileConfig = data => {
  return api({
    url: '/admin/employ/settings/getProfileConfig',
    method: 'post',
    data
  });
};
export const saveProfileConfig = data => {
  return api({
    url: '/admin/employ/settings/saveProfileConfig',
    method: 'post',
    data
  });
};
export const getDeliverConfig = data => {
  return api({
    url: '/admin/employ/settings/getDeliverConfig',
    method: 'post',
    data
  });
};
export const saveDeliverConfig = data => {
  return api({
    url: '/admin/employ/settings/saveDeliverConfig',
    method: 'post',
    data
  });
};
